import axios from "axios";

const deletesharedTenant = async (TenantID: Number, TenantUUID: String) => {
  //
  try {
    const request = await axios.delete(
      `${process.env.REACT_APP_DELETE_SHARED_TENANT_API}/${TenantID}`
    );
    console.log(request);
    //console.log(request.data);
    // await setSessionStorage(request.data);

    return request;
  } catch (err) {
    console.log(err);
    throw err;
  }
  //
};

export { deletesharedTenant };
