import React, { useState } from "react";
//notify
import {
  notifyError,
  notifySuccess,
} from "../../../Components/Notifications/Notifications";
import { superAdmin } from "../../../api/notificationAPI";

const TenantSpecific = () => {
  const [tenantId, setTenantId] = useState(""); // State for tenant ID
  const [tenantUuid, setTenantUuid] = useState(""); // State for tenant UUID
  const [message, setMessage] = useState("");
  const [NotificationType, setNotificationType] = useState("");
  // const [isDeleting, setIsDeleting] = useState(false); // State to control deletion state
  //const [message, setMessage] = useState(""); // State to display messages

  const handleSendNotification = async (e: any) => {
    e.preventDefault();
    if (tenantId || tenantUuid) {
      try {
        await superAdmin(
          tenantId as unknown as Number,
          tenantUuid,
          message,
          NotificationType
        );
        notifySuccess("");
      } catch (err) {
        notifyError("");
      }
    }
  };
  return (
    <div>
      {" "}
      <div className="delete-shared-tenant">
        <h1>For Super Admin</h1>
        <form onSubmit={handleSendNotification}>
          <input
            type="text"
            value={tenantId}
            onChange={(e) => setTenantId(e.target.value)}
            placeholder="Enter Tenant ID"
            className="tenant-input"
            required
          />
          <input
            type="text"
            value={tenantUuid}
            onChange={(e) => setTenantUuid(e.target.value)}
            placeholder="Enter Tenant UUID"
            className="tenant-input"
            required
          />
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Notification Message"
            className="tenant-input"
            required
          />
          <select
            onChange={(e) => setNotificationType(e.target.value)}
            required
          >
            <option value="">Notification Type</option>
            <option value="info">Info</option>
            <option value="success">Success</option>
            <option value="success">Success</option>
            <option value="warning">Warning</option>
            <option value="error">Error</option>
          </select>
          <button type="submit" className="delete-button" />
        </form>
      </div>
    </div>
  );
};

export default TenantSpecific;
