import React, { useState } from "react";
import {
  notifyError,
  notifySuccess,
} from "../../../Components/Notifications/Notifications";

//api
import { revoceTenantModules } from "../../../api/moduleAPI";

const TenantModuleRevoce = () => {
  const [tenantId, setTenantId] = useState("");
  const [tenantUuid, setTenantUuid] = useState("");
  const [moduleID, setModuleID] = useState("");

  //methods
  const handleTenantModuleRevocation = async (e: any) => {
    e.preventDefault();
    if (tenantId || tenantUuid || moduleID) {
      try {
        await revoceTenantModules(
          tenantId as unknown as Number,
          tenantUuid,
          moduleID as unknown as Number
        );
        notifySuccess("Successful Tenant Module Revoce");
      } catch (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          notifyError(error.response.data);
          //console.log(error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          notifyError(error.request);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          notifyError(error.message);
          //console.log("Error", error.message);
        }
      }
    }
  };
  //
  return (
    <div className="delete-shared-tenant">
      <h1>Revoce Tenant Module</h1>
      <form onSubmit={handleTenantModuleRevocation}>
        <input
          type="text"
          value={tenantId}
          onChange={(e) => setTenantId(e.target.value)}
          placeholder="Enter Tenant ID"
          className="tenant-input"
          required
        />
        <input
          type="text"
          value={tenantUuid}
          onChange={(e) => setTenantUuid(e.target.value)}
          placeholder="Enter Tenant UUID"
          className="tenant-input"
          required
        />
        <input
          type="text"
          value={moduleID}
          onChange={(e) => setModuleID(e.target.value)}
          placeholder="Enter Module UUID"
          className="tenant-input"
          required
        />
        <button type="submit" className="delete-button" />
      </form>
    </div>
  );
};

export default TenantModuleRevoce;
