import React from "react";
import "./App.css";
//notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { Routes, Route, Navigate } from "react-router-dom";
//auth
// import { useAuthContext } from "./hooks/authcontext";
import ProtectedRoute from './Components/ProtectedRoute';

//pages
import Login from "./pages/login";
import Landing from "./pages/landing/landing";
//

function App() {
  return (
    <div className="App">
      <ToastContainer />

      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/landing/*"
          element={
            <ProtectedRoute>
              <Landing />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
