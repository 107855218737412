import React from "react";
import "./tenantMng.css";
//
import { Routes, Route } from "react-router-dom";
//pages
import TenantMngDashboard from "./tenantMngDashboard/tenantMngDashboard";
import NewTenat from "./newTenant/newTenant";
import NewSharedTenant from "./newSharedTenant/newSharedTenant";
import DeleteSharedTenant from "./deleteSharedTenant/DelteSharedTenant";
import TenantModuleExpansion from "./TenantModuleExpansion/TenantModuleExpantion";
import TenantModuleRevoce from "./TenantModuleRevoce/TenantModuleRevoce";
//

const TenantMng = () => {
  return (
    <div className="TenantMng">
      <Routes>
        <Route path="/" element={<TenantMngDashboard />}></Route>
        <Route path="/new_tenant" element={<NewTenat />}></Route>
        <Route path="/new_shared_tenant" element={<NewSharedTenant />}></Route>
        <Route
          path="/delete_shard_tenant"
          element={<DeleteSharedTenant />}
        ></Route>
        <Route
          path="/expand_tenant_module"
          element={<TenantModuleExpansion />}
        ></Route>
         <Route
          path="/revoce_tenant_module"
          element={<TenantModuleRevoce />}
        />
      </Routes>
    </div>
  );
};

export default TenantMng;
