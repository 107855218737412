import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/authcontext';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { authed } = useAuthContext();
  
  if (!authed) {
    // Redirect to login if not authenticated
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute; 