import React, { useState } from "react";
import "./deleteSharedTenant.css"; // Importing CSS for styling
import { deletesharedTenant } from "../../../api/deteleTenantAPI";
//method

const DeleteSharedTenant = () => {
  const [tenantId, setTenantId] = useState(""); // State for tenant ID
  const [tenantUuid, setTenantUuid] = useState(""); // State for tenant UUID
  // const [isDeleting, setIsDeleting] = useState(false); // State to control deletion state
  //const [message, setMessage] = useState(""); // State to display messages

  const handleDelete = async (e: any) => {
    e.preventDefault();
    if (tenantId || tenantUuid) {
      try {
        await deletesharedTenant(tenantId as unknown as Number, tenantUuid);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="delete-shared-tenant">
      <h1>Delete Shared Tenant</h1>
      <form onSubmit={handleDelete}>
        <input
          type="text"
          value={tenantId}
          onChange={(e) => setTenantId(e.target.value)}
          placeholder="Enter Tenant ID"
          className="tenant-input"
          required
        />
        <input
          type="text"
          value={tenantUuid}
          onChange={(e) => setTenantUuid(e.target.value)}
          placeholder="Enter Tenant UUID"
          className="tenant-input"
          required
        />
        <button type="submit" className="delete-button" />
      </form>
    </div>
  );
};

export default DeleteSharedTenant;
