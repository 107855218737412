import React, { useState } from "react";
import "./login.css";
//notifications
import {
  notifyError,
  notifySuccess,
} from "../Components/Notifications/Notifications";
//auth
import { useAuthContext } from "../hooks/authcontext";
//ant design
import { Spin } from "antd";
import { Input } from "antd";
//Api
import { authSignIn } from "../api/loginAPI";
//
import "react-toastify/dist/ReactToastify.css";
//react router
import { useNavigate } from "react-router-dom";
//

const Login = () => {
  //
  const { login } = useAuthContext();
  //declarations
  const navigate = useNavigate();
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //const [logoLoading, setLogoLoading] = React.useState<boolean>(false);
  //states
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  //

  // Redirect if already authenticated
  // React.useEffect(() => {
  //   if (authed) {
  //     navigate("/landing", { replace: true });
  //   }
  // }, [authed, navigate]);

  const HandleSignIn = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const call = await authSignIn({
        email: Email,
        password: Password,
      });

      if (!call) {
        notifyError("Server error");
        return;
      }

      // First update the auth state
      await login();

      // Then show success message and navigate
      notifySuccess(call.data.message);
      navigate("/landing", { replace: true });
    } catch (err: any) {
      const errorMessage =
        err.response?.data || err.message || "An error occurred";
      notifyError(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  //
  return (
    <div className="AdminLogin">
      <div className="header">
        <h1>HabSync</h1>
      </div>
      <div className="loginContainer">
        <h2>Welcome Back, Admin</h2>
        <Spin spinning={loading}>
          <form onSubmit={HandleSignIn} className="loginForm">
            <div className="formGroup">
              <input
                type="email"
                placeholder="Email"
                className="inputField"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input.Password
                placeholder="Password"
                className="inputField"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className="loginButton">
                Login
              </button>
            </div>
          </form>
        </Spin>
      </div>
    </div>
  );
};

export default Login;
