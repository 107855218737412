import React from "react";
import "./tenantMngDashboard.css";
//
import { Link } from "react-router-dom";

const TenantMngDashboard = () => {
  return (
    <div className="tenant-management">
      <h1>Tenant Management Dashboard</h1>

      <section className="tenant-section">
        <h2>Create Tenants</h2>
        <div className="MenuCards">
          <div className="card">
            <Link to="new_tenant">New Self Hosted DB Tenant</Link>
          </div>
          <div className="card">
            <Link to="new_trial_tenant">New Trial Tenant</Link>
          </div>
          <div className="card">
            <Link to="new_shared_tenant">New Shared DB Tenant</Link>
          </div>
          <div className="card">
            <Link to="new_dedicated_tenant">New Dedicated DB Tenant</Link>
          </div>
        </div>
      </section>

      <section className="tenant-section">
        <h2>Manage Tenants</h2>
        <div className="MenuCards">
          <div className="card">
            <Link to="view_tenants">View All Tenants</Link>
          </div>
          <div className="card">
            <Link to="delete_shard_tenant">Delete Shard DB Tenant</Link>
          </div>
          <div className="card">
            <Link to="edit_tenant">Edit Tenant</Link>
          </div>
        </div>
      </section>

      <section className="tenant-section">
        <h2>Manage Tenants Module</h2>
        <div className="MenuCards">
          <div className="card">
            <Link to="expand_tenant_module">Tenant Module Expansion</Link>
          </div>
          <div className="card">
            <Link to="revoce_tenant_module">Tenant Module Revocation</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TenantMngDashboard;
