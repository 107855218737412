import axios from "axios";

const superAdmin = async (
  TenantID: Number,
  TenantUUID: String,
  message: String,
  type: String
) => {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_NOTIFICATION_SADMIN}/${TenantID}`,
      {
        TenantUUID: TenantUUID,
        message: message,
        type: type,
      }
    );
    console.log(request);
    return request;
  } catch (err) {
    console.log(err);
    throw err;
  }
  //
};

export { superAdmin };
