//notification
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//

const notifyInfo = (msg: string) =>
  toast.info(msg || "Info!", {
    position: "top-right",
    theme: "colored",
  });
const notifySuccess = (msg: string) =>
  toast.success(msg || "Success!", {
    position: "top-right",
    theme: "colored",
  });

const notifyWarning = (msg: string) =>
  toast.warn(msg || "Warning!", {
    position: "top-right",
    theme: "colored",
  });

const notifyError = (msg: any) =>
  toast.error(msg || "Error!", {
    position: "top-right",
    theme: "colored",
  });

export { notifyInfo, notifySuccess, notifyWarning, notifyError };
