import axios from "axios";
import { sessionStorage } from "es-storage";

const authSignIn = async (props: any) => {
  //
  try {
    const request = await axios.post(`${process.env.REACT_APP_LOGIN_API}`, {
      // tenantID: props.tenantID,
      email: props.email,
      password: props.password,
    });
    await setSessionStorage(request.data);

    return request;
  } catch (error) {
    //console.log(error);
    //use throw to return it as error
    throw error;
  }
};

const setSessionStorage = async (data: any) => {
  try {
    await sessionStorage.set("HS_token", data.token);
  } catch (err) {
    throw err;
  }
};

export { authSignIn };
