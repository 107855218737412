import React from "react";
import { Col, Divider, Row } from "antd";
import { Route, Routes, Link } from "react-router-dom";
//pages
import TenantSpecific from "./TenantSpecific/TenantSpecific";

const style: React.CSSProperties = { background: "#0092ff", padding: "8px 0" };

const NotificationMng = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Divider orientation="left">Global (All Tenants)</Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={6}>
                  <div style={style}>col-6</div>
                </Col>
              </Row>
              <Divider orientation="left">Tenant-Specific:</Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={6}>
                  <div className="card">
                    <Link to="tenant_superadmin">For Super Admin</Link>
                  </div>
                </Col>
              </Row>
              <Divider orientation="left">User-Specific:</Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={6}>
                  <div style={style}>col-6</div>
                </Col>
              </Row>
            </>
          }
        ></Route>
        <Route path="/tenant_superadmin" element={<TenantSpecific />}></Route>
      </Routes>
    </div>
  );
};

export default NotificationMng;
